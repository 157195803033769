"use client";

import React, { useEffect, useState } from "react";
function ClientProfilePictureLoader({
  size
}) {
  const [isHydrated, setIsHydrated] = useState(false);
  useEffect(() => {
    setIsHydrated(true);
  }, []);
  if (isHydrated) return <p></p>;
  return <p className={"bg-grey -mt-8 mb-8 animate-pulse rounded-full"} style={!isHydrated ? {
    width: size,
    height: size
  } : {}} data-sentry-component="ClientProfilePictureLoader" data-sentry-source-file="client-profile-picture-loader.tsx"></p>;
}
export default ClientProfilePictureLoader;